import {Euler, Vector3, Scene, SpotLight, PointLight, DirectionalLight,AmbientLight,Color} from "three";
import {thisIsMobile} from "./GlobalConstants";

export const LIGHTPROBE_CONFIG = {
    useProbe:true, //only used if Cubemap is available
    intensity:1,
    name:'lightprobe-01'
};
//6 sides  direct light around the object. 0 intensity will disable the light
export const SIDES_LIGHT_CONFIG = {

    generateSidesLights:false,
    XPosIntensity:1.1, // set to zero to be ignored
    XPosColor:new Color(0xFFFFFF),
    XNegIntensity:1.1,
    XNegColor:new Color(0xFFFFF),
    ZPosIntensity:1.1,
    ZPosColor:new Color(0xFF0000),
    ZNegIntensity:1.1,
    ZNegColor:new Color(0xFF0000),
    BottomIntensity:1,
    BottomColor:new Color(0xFF0000),
    TopIntensity:0,
    TopColor:new Color(0xFF),

    unifiedColor: new Color(0xFFFF00), //if above individual color not specified then set color of light to this
}


//mobile version
export const LIGHTS = [
    {
        name: 'Ambient',
        light: new AmbientLight(),
        color: new Color(0x7c7a6e),
        intensity: 0.9,

    },
    {
        name: 'Direct-1',
        light: new DirectionalLight(),
        position: new Vector3(5.4,10,24.8 ),
        target: new Vector3(9,-11.3,-11.9),
        color: new Color(0xc9b47e),
        intensity: 0.8 * Math.PI,

    },
    {
        name: 'Direct-2',
        light: new DirectionalLight(),
        position: new Vector3(5.4,10,-28.6 ),
        target: new Vector3(9,-11.3,-11.9),
        color: new Color(0xffffff),
        intensity: 0.8 * Math.PI,

    },
    {
        name: 'Direct-3',
        light: new DirectionalLight(),
        position: new Vector3(-27.76,10,-6.7 ),
        target: new Vector3(9,-11.3,-11.9),
        color: new Color(0xffffff),
        intensity: 0.8 * Math.PI,

    },
    {
        name: 'Direct-4',
        light: new DirectionalLight(),
        position: new Vector3(27.76,10,-4 ),
        target: new Vector3(9,-11.3,-11.9),
        color: new Color(0xbaa57b),
        intensity: 0.8 * Math.PI,

    },
   /* {
        name: 'PointLightCenter',
        light: new PointLight(),
        position: new Vector3(0, 1, 0),
        color: 0xffffff,
        intensity: 2.5,
        decay: .65,
        distance: 15,
    },

    {
        name: 'PointLightChandlier',
        light: new PointLight(),
        position: new Vector3(0, 1, 0),
        color: 0xffffff,
        intensity: 4.1,
        decay: 1.3,
        distance: 2.5,
    },

    {
        name: 'PointLightCeiling2',
        light: new PointLight(),
        position: new Vector3(0, 3.4, 0),
        color: 0xffffff,
        intensity: 2,
        decay: 1.1,
        distance: 8.3,
    },*/
]
