export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: 'none'
  },
  {
    id: 'MForbesSky4',
    name: 'M. Forbes Sky4',
    path: 'assets/environment/MForbesSKYHDRI13_low.hdr',
    format: 'hdr'
  },
  // {
  //   id: 'canary_wharf',
  //   name: 'canary_wharf',
  //   path: '/assets/environment/canary_wharf/',
  //   format: 'cubemap',
  //   images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  // },
  // {
  //   id: 'photoStudio',
  //   name: 'photoStudio',
  //   path: '/assets/environment/photoStudio/',
  //   format: 'cubemap',
  //   images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  // },
  // {
  //   id: 'canary_wharf_4k.hdr',
  //   name: 'Houser Wirth (HDR)',
  //   path: '/assets/environment/canary_wharf_4k.hdr',
  //   format: 'hdr'
  // },
  // {
  //   id: 'spiaggia_di_mondello_4k',
  //   name: 'spiaggia_di_mondello_4k',
  //   path: '/assets/environment/spiaggia_di_mondello_4k/',
  //   format: 'cubemap',
  //   images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  // },


];


