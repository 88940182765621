import React from "react";

const VideoComponent = (props) => {
    return (
        <video id={props.id}  playsInline style={{display: 'none'}} >
            <source src={props.src} type='video/mp4 "avc1.42E01E, mp4a.40.2"'/>
            <source src={props.src} type='video/ogg codecs="theora, vorbis"'/>
        </video>
    )
}

export default VideoComponent;
