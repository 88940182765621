
import {Euler, Vector3,Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR:2,
};
// Here we split by Artists Names
const CameraLocationsDB = [

    {
        cameraLocationId: 'W1P1',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Main Gallery',
        cameraPosition:new Vector3(-0.262,-10.5,-12.0), // use THREE.Vector3
        cameraRotation: new Euler(0,-1.492,0,'XYZ'),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W3P2',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Projection Room',
        cameraPosition:new Vector3(1.925,-10.5,-3.135), // use THREE.Vector3
        cameraRotation: new Euler(0,1.531,0,'XYZ'),  //use THREE.Euler
    },
];

export {CameraLocationsDB, FLOOR_NUMBER};
