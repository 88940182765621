const ColliderDB = [
    {
        colliderId:'borderCollider',
        displayCollider:'N',
    },
    {
        colliderId:'F02S01Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F02S02Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F02S03Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F01S01Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F01S02Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F01S03Collider',
        displayCollider:'N',
    },
    {
        colliderId:'F01S04Collider',
        displayCollider:'N',
    },
    {
        colliderId:'StandingBody01Collider',
        displayCollider:'N',
    },
];

export default ColliderDB;
