import {Euler, Vector3} from "three";

export  const clientOptions = {
    useGUI: 'N',
    initCameraOrient: {

        position: new Vector3(4.76,  -10.5, -2.0),
        rotation: new Euler(0, Math.PI*731/180 , 0, 'XYZ')

    },
    gammaOutput: 'sRGB', //ignore for now 'Linear'
    gammaInput: 'Y', //ignore for now
    usePaintingsIdForEncoding: 'Y', //if 'Y', then apply  paintingTextureEncoding setting to each painting tagged with painting_id
    exposure: 0.51,
    textureEncoding: 'sRGB',  //'Linear', //'sRGB',
    paintingTextureEncoding: 'sRGB', //'Linear', //'sRGB',
    physicallyCorrectLights: 'Y', //if not presented in the Viewer.js then must be set to 'Y'
    fov: 45, //Camera field of view
    outputEncoding: 'sRGB', //'Linear' or 'sRGB',
    envMapIntensity: 1, // if envMap is not used (colorMap = 0) then this property has nos no effect
    envMap: {
        colorMap: 1, //envmap for light. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
        backgroundMap: 1, //envmap for background image. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
    },
    modelName:'MichaelForbesShow01_067.glb',
}
