
import {ANNOTATION_VIDEO_DISPLAY,ANNOTATION_PARENT,VIDEO_PLAYING_MODE} from './GlobalConstants';
import * as THREE from 'three';

export const ANNOTATION_CONTACT_TEXT = '';
export const CONTACT_URL = 'WEBSITE';
export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';
export const SPOTIFY = 'SPOTIFY';
export const TWITTER = 'TWITTER';
export const TIKTOK = 'TIKTOK';
export const IMDB = 'IMDB';
export const FACEBOOK = 'FACEBOOK';
export const ENQUIRE_EMAIL = 'ENQUIRE';
export const LINKED_IN = 'LINKEDIN';
export const HAS_MEDIA_CAROUSEL_LINK = 'MORE';
export const SEND_TO_EMAIL = 'SEND TO';

const AnnotationsDB = [


    {
        paintingId: 'W04L01',
        videoId: 'W04L01',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L01.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L01',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L02',
        videoId: 'W04L02',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L02.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L02',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L03',
        videoId: 'W04L03',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L03.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L03',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L04',
        videoId: 'W04L04',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L04.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L04',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L05',
        videoId: 'W04L05',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L05.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L05',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L06',
        videoId: 'W04L06',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L06.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L06',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L07',
        videoId: 'W04L07',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L07.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L07',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L08',
        videoId: 'W04L08',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L08.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L08',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },

    {
        paintingId: 'W04L09',
        videoId: 'W04L09',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L09.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L09',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L10',
        videoId: 'W04L10',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L10.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L10',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },
    {
        paintingId: 'W04L11',
        videoId: 'W04L11',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L11.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L11',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },

    {
        paintingId: 'W04L12',
        videoId: 'W04L12',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L12.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L12',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS,
        videoLoop:true,
    },

    {
        paintingId: 'W05V01',
        videoId: 'W05V01',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W05V01.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W05V01',
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        videoButtonScale: {x:.2,y:.2,z:.2},
        videoButtonPos:{x:-3.5,y:-11.2,z:-2.85},
        videoPlaySpriteImageURL: '/assets/sprites/videoPlayButtonBlack.svg',
        videoPauseSpriteImageURL: '/assets/sprites/videoPauseButtonBlack.svg',
        videoTextureEncoding: THREE.sRGBEncoding,

    },

];



export {AnnotationsDB};
