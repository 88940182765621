const TeleportDB = [
    {
        teleportPointId:'teleportBottomA',
        displayTeleportPoint:'N',
    },
    {
        teleportPointId:'teleportTopA',
        displayTeleportPoint:'N',
    }
];

export default TeleportDB;
