import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            {/*<VideoComponent id="W2V1" src={process.env.PUBLIC_URL + `/videos/W2V1.mp4`}/>*/}

            <VideoComponent id="W04L01" src={process.env.PUBLIC_URL + `/videos/W04L01.mp4`}/>
            <VideoComponent id="W04L02" src={process.env.PUBLIC_URL + `/videos/W04L02.mp4`}/>
            <VideoComponent id="W04L03" src={process.env.PUBLIC_URL + `/videos/W04L03.mp4`}/>
            <VideoComponent id="W04L04" src={process.env.PUBLIC_URL + `/videos/W04L04.mp4`}/>
            <VideoComponent id="W04L05" src={process.env.PUBLIC_URL + `/videos/W04L05.mp4`}/>
            <VideoComponent id="W04L06" src={process.env.PUBLIC_URL + `/videos/W04L06.mp4`}/>
            <VideoComponent id="W04L07" src={process.env.PUBLIC_URL + `/videos/W04L07.mp4`}/>
            <VideoComponent id="W04L08" src={process.env.PUBLIC_URL + `/videos/W04L08.mp4`}/>
            <VideoComponent id="W04L09" src={process.env.PUBLIC_URL + `/videos/W04L09.mp4`}/>
            <VideoComponent id="W04L10" src={process.env.PUBLIC_URL + `/videos/W04L10.mp4`}/>
            <VideoComponent id="W04L11" src={process.env.PUBLIC_URL + `/videos/W04L11.mp4`}/>
            <VideoComponent id="W04L12" src={process.env.PUBLIC_URL + `/videos/W04L12.mp4`}/>
            <VideoComponent id="W05V01" src={process.env.PUBLIC_URL + `/videos/W05V01.mp4`}/>

        </div>
    )
}

export default VideosComponent;
